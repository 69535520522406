
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { Options, Vue, prop } from "vue-class-component";

class Props {
  modelValue = prop<any>({
    required: true
  });
  type = prop<string>({
    default:
      ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .xlsx, .csv, .xls,", // csv, xlsx
    type: String
  });
  isDraggable = prop<boolean>({
    default: true,
    type: Boolean
  });
  maxSize = prop<number>({
    default: 2, // size in MB
    type: Number
  });
  customClass = prop<string>({
    default: "p-1",
    type: String
  });
  caption = prop<string>({
    default: "",
    type: String
  });
  fileCaption = prop<string>({
    default: "File",
    type: String
  });
  captionError = prop<string>({
    default: "",
    type: String
  });
  url = prop<string>({
    default: "",
    type: String
  });
  name = prop<string>({
    default: "",
    type: String
  });
  allowed = prop<string>({
    default: "",
    type: String
  });
  maxFile = prop<number>({
    default: 1,
    type: Number
  });
}

@Options({
  emits: ["update:modelValue"]
})
export default class MultipleUploadFile extends Vue.with(Props) {
  isDataValidate = false;
  loadingUpload = false;
  isDragFile = false;
  isFileImage = false;
  files: any = [];

  $refs!: {
    file: HTMLFormElement;
  };

  setDragAndDrop(e: any, value: boolean) {
    this.isDragFile = value;
    e.preventDefault();
  }

  setError() {
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  setValue(file: any) {
    this.files.push(file);
    this.isDataValidate = false;
    this.$emit("update:modelValue", this.files);
    this.isDragFile = false;
  }

  onUploadFile(file: any) {
    if (file.size < 1024 * 1024 * this.maxSize) {
      this.setValue(file);
    } else {
      this.setError();
    }
  }

  tempIndexToRemove = -1;
  removeFile(index: number) {
    this.tempIndexToRemove = index;
    if (this.tempIndexToRemove > -1) {
      this.files.splice(this.tempIndexToRemove, 1);
      this.tempIndexToRemove = -1;
    }
  }

  onSubmitClick(e: any) {
    try {
      if (e.target.files.length) {
        const obj = Object.keys(e.target.files);
        obj.forEach((item: any) => {
          if (this.files.length < this.maxFile) {
            this.onUploadFile(e.target.files[item]);
          }
        });
        return;
      }
    } catch (err) {
      this.$emit("update:modelValue", []);
      this.setError();
    }
  }

  // Drag File
  onSubmitDrag(e: any) {
    e.preventDefault();
    try {
      if (e.dataTransfer.files.length) {
        const obj = Object.keys(e.dataTransfer.files);
        obj.forEach((item: any) => {
          if (this.files.length < this.maxFile) {
            this.onUploadFile(e.dataTransfer.files[item]);
          }
        });
        return;
      }
    } catch (err) {
      this.$emit("update:modelValue", []);
      this.setError();
    } finally {
      this.isDragFile = false;
    }
  }

  setDefault() {
    this.$emit("update:modelValue", []);
    this.files = [];
    this.$refs.file.value = "";
    this.isDataValidate = false;
    this.loadingUpload = false;
    this.isDragFile = false;
  }

  // Ellipsis String
  ellipsisString(value: string) {
    return ellipsisString(value, 50);
  }

  resetTempsFile() {
    this.files = [];
    this.$emit("update:modelValue", []);
    this.isDataValidate = false;
  }
}
