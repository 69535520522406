import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "text-14px text-gray-lp-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.file.click())),
      onDragover: _cache[2] || (_cache[2] = e => _ctx.setDragAndDrop(e, true)),
      onDragleave: _cache[3] || (_cache[3] = e => _ctx.setDragAndDrop(e, false)),
      onDrop: _cache[4] || (_cache[4] = e => _ctx.onSubmitDrag(e)),
      class: 
        `${_ctx.customClass} flex flex-col justify-center cursor-pointer items-center text-12px border-2 rounded-lg my-2 ${
          _ctx.files.length
            ? 'border-red-lp-100 bg-red-lp-100'
            : 'border-red-lp-100 bg-white'
        }`
      
    }, [
      _createVNode(_component_Icons, {
        name: _ctx.name === 'Foto' ? 'image-upload' : 'file-upload',
        class: _ctx.files.length ? `text-white w-4` : `text-red-lp-100 w-4`
      }, null, 8, ["name", "class"])
    ], 34),
    _createVNode("p", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createVNode("input", {
      type: "file",
      ref: "file",
      onChange: _cache[5] || (_cache[5] = e => (_ctx.isDragFile ? _ctx.onSubmitDrag(e) : _ctx.onSubmitClick(e))),
      accept: _ctx.allowed || _ctx.type,
      class: "hidden"
    }, null, 40, ["accept"])
  ]))
}